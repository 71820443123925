import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import AuthService from "@/services/AuthService";
import { MenuOptionsIds, TabbarIds, MenuMobileIds } from "@/enums/HousfyPage";

jest.mock("@/services/MyRentalsConfig");

const headerOptions = {
  menuOptionsMobile: [
    {
      label: undefined,
      subitems: [
        {
          id: MenuOptionsIds.ACTIVITY,
          label: "common.yourActivity",
          link: "http://localhost:30001/",
          icon: "icons/homeTick",
          tracking: "link_header_menu_your-activity",
          devices: ["mobile"],
        },
        {
          id: MenuOptionsIds.TOOLS,
          label: "common.tools",
          link: "http://localhost:30001/tools",
          icon: "icons/tools",
          tracking: "link_header_menu_tools",
          devices: ["mobile"],
        },
        {
          id: MenuOptionsIds.MENU,
          label: "common.menu",
          icon: "icons/menu",
          tracking: "link_header_menu_menu",
          devices: ["mobile"],
        },
      ],
    },
    {
      label: undefined,
      subitems: [
        {
          id: MenuOptionsIds.LOGOUT,
          label: "common.logout",
          icon: "icons/logout",
          link: "http://localhost:3000/logout",
          tracking: "link_header_menu_logout",
          devices: ["mobile", "desktop"],
        },
      ],
    },
  ],
  menuOptionsDesktop: [
    {
      label: "common.yourAccount",
      subitems: [
        {
          id: MenuOptionsIds.ACCOUNT,
          icon: "icons/gear",
          label: "common.configuration",
          link: "http://localhost:3000/dashboard/perfil",
          tracking: "link_header_menu_account-settings",
          devices: ["desktop"],
        },
        {
          id: MenuOptionsIds.FRIEND,
          icon: "icons/gift",
          label: "common.inviteAFriend",
          link: "http://localhost:3000/dashboard/referral-friend",
          tracking: "link_header_menu_referral-friend",
          devices: ["desktop"],
        },
      ],
    },
    {
      label: "common.customerService",
      subitems: [
        {
          id: MenuOptionsIds.SUGGESTIONS,
          label: "common.suggestionsAndComplaints",
          icon: "icons/interrogation-circle",
          link: "http://localhost:3000/help",
          tracking: "link_header_menu_suggestions",
          devices: ["desktop"],
        },
        {
          id: MenuOptionsIds.CONTACT,
          icon: "icons/phone",
          label: "common.contactHousfy",
          link: "http://localhost:30001/contact",
          tracking: "link_header_menu_contact",
          devices: ["desktop"],
        },
      ],
    },
    {
      subitems: [
        {
          id: MenuOptionsIds.LOGOUT,
          label: "common.logout",
          icon: "icons/logout",
          link: "http://localhost:3000/logout",
          tracking: "link_header_menu_logout",
          devices: ["mobile", "desktop"],
        },
      ],
    },
  ],
  horizontalOptions: [
    {
      id: MenuOptionsIds.FRIEND,
      devices: ["mobile", "desktop"],
      icon: "icons/gift",
      label: "common.win200euros",
      link: "http://localhost:3000/dashboard/referral-friend",
      tracking: "link_header_main_referral",
    },
  ],
};

const mainLogo = {
  devices: ["mobile", "desktop"],
  icon: "logos/housfy",
  label: "Housfy",
  link: "http://localhost:3000/",
  tracking: "link_header_main_logo",
};

const tabbarOptions = [
  [
    {
      id: TabbarIds.ACTIVITY,
      icon: "icons/house_check",
      label: "common.yourActivity",
      link: "http://localhost:30001/",
      tracking: "link_tabbar_your-activity",
      devices: ["mobile", "desktop"],
    },
    {
      id: TabbarIds.TOOLS,
      icon: "icons/squares",
      label: "common.tools",
      link: "http://localhost:30001/tools",
      tracking: "link_tabbar_tools",
      devices: ["mobile", "desktop"],
    },
    {
      id: TabbarIds.MENU,
      icon: "icons/hamburguer",
      label: "",
      tracking: "link_tabbar_menu",
      devices: ["mobile"],
    },
  ],
  [
    {
      id: TabbarIds.VISITS,
      icon: "icons/calendar",
      label: "common.visitsCalendar",
      link: "http://localhost:3000/dashboard/visitas",
      tracking: "link_tabbar_visits-calendar",
      devices: ["desktop"],
    },
    {
      id: TabbarIds.DOCUMENTS,
      icon: "icons/folder",
      label: "common.documents",
      link: "http://localhost:3000/dashboard/documentos",
      tracking: "link_tabbar_documents",
      devices: ["desktop"],
    },
    {
      id: TabbarIds.FAVORITES,
      icon: "icons/heart",
      label: "common.yourFavorites",
      link: "http://localhost:3000/dashboard/favoritos",
      tracking: "link_tabbar_your-favorites",
      devices: ["desktop"],
    },
    {
      id: TabbarIds.SEARCHES,
      icon: "icons/bookmark",
      label: "common.savedSearches",
      link: "http://localhost:3000/dashboard/saved-searches",
      tracking: "link_tabbar_saved-searches",
      devices: ["desktop"],
    },
  ],
];

const menuMobile = {
  title: "common.menu",
  tabs: [
    {
      label: undefined,
      subitems: [
        {
          id: MenuMobileIds.VISITS,
          icon: "icons/calendar",
          label: "common.calendarOfVisits",
          link: "http://localhost:3000/dashboard/visitas",
          tracking: "link_menu-mobile_visits-calendar",
        },
        {
          id: MenuMobileIds.DOCUMENTS,
          icon: "icons/folder",
          label: "common.documents",
          link: "http://localhost:3000/dashboard/documentos",
          tracking: "link_menu-mobile_documents",
        },
        {
          id: MenuMobileIds.FAVORITES,
          icon: "icons/heart",
          label: "common.yourFavorites",
          link: "http://localhost:3000/dashboard/favoritos",
          tracking: "link_menu-mobile_your-favorites",
        },
        {
          id: MenuMobileIds.SEARCHES,
          icon: "icons/bookmark",
          label: "common.savedSearches",
          link: "http://localhost:3000/dashboard/saved-searches",
          tracking: "link_menu-mobile_saved-searches",
        },
      ],
    },
    {
      label: "common.yourAccount",
      subitems: [
        {
          id: MenuMobileIds.ACCOUNT,
          icon: "icons/gear",
          label: "common.accountSettings",
          link: "http://localhost:3000/dashboard/perfil",
          tracking: "link_menu-mobile_account-settings",
        },
        {
          id: MenuMobileIds.FRIEND,
          icon: "icons/gift",
          label: "common.inviteAFriend",
          link: "http://localhost:3000/dashboard/referral-friend",
          tracking: "link_menu-mobile_referral-friend",
        },
      ],
    },
    {
      label: "common.customerService",
      subitems: [
        {
          id: MenuMobileIds.SUGGESTIONS,
          icon: "icons/interrogation-circle",
          label: "common.suggestionsAndComplaints",
          link: "http://localhost:3000/help",
          tracking: "link_menu-mobile_help",
        },
        {
          id: MenuMobileIds.CONTACT,
          icon: "icons/phone",
          label: "common.contactWithHousfy",
          link: "http://localhost:30001/contact",
          tracking: "link_menu-mobile_contact",
        },
      ],
    },
  ],
  cta: {
    label: "common.logout",
    link: "http://localhost:3000/logout",
    tracking: "button_menu-mobile_logout",
  },
  footer: [
    {
      label: "common.termsAndConditions",
      link: "http://localhost:3000/legal/terminos",
      tracking: "link_menu-mobile_terms-conditions",
    },
    {
      label: "common.withdrawal",
      link: "http://localhost:3000/legal/desistimiento",
      tracking: "link_menu-mobile_withdrawal",
    },
    {
      label: "common.privacyPolicy",
      link: "http://localhost:3000/legal/privacidad-usuarios",
      tracking: "link_menu-mobile_privacy-policy",
    },
    {
      label: "common.cookies",
      link: "http://localhost:3000/legal/cookies",
      tracking: "link_menu-mobile_cookies",
    },
  ],
};

const component = (data: any = {}, props: any = {}, mocks: any = {}) => {
  return myRentalsShallowMount(HousfyPage, {
    data,
    props,
    mocks,
  });
};

describe("HousfyPage", () => {
  testMyRentalsShallowMount(HousfyPage);

  it("Should correctly return the menu options when device is mobile", () => {
    // given
    const mocks = {
      $mqm: true,
    };
    const page = component({}, {}, mocks);
    // when
    // then

    expect(page.vm.headerOptions.menuOptions).toEqual(
      headerOptions.menuOptionsMobile
    );
  });

  it("Should correctly return the menu options when device is desktop", () => {
    // given
    const mocks = {
      $mqm: false,
      $mqd: true,
    };
    const page = component({}, {}, mocks);
    // when
    // then

    expect(page.vm.headerOptions.menuOptions).toEqual(
      headerOptions.menuOptionsDesktop
    );
  });

  it("Should correctly return the horizontal options", () => {
    const page = component();

    expect(page.vm.headerOptions.horizontalOptions).toEqual(
      headerOptions.horizontalOptions
    );
  });

  it("Should correctly return main logo", () => {
    const page = component();

    expect(page.vm.mainLogo).toEqual(mainLogo);
  });

  it("Should correctly return the tabbar", () => {
    const page = component();

    expect(page.vm.tabs).toEqual(tabbarOptions);
  });

  it("Should correctly return the menu mobile", () => {
    const page = component();

    expect(page.vm.menuMobile).toEqual(menuMobile);
  });

  it("Should correctly set that it's impersonating", () => {
    spyOn(AuthService, "isImpersonate").and.returnValue(true);
    const page = component();

    expect(page.vm.isImpersonation).toEqual(true);
  });

  it("Should correctly set that it's not impersonating", () => {
    spyOn(AuthService, "isImpersonate").and.returnValue(false);
    const page = component();

    expect(page.vm.isImpersonation).toEqual(false);
  });

  describe("computed", () => {
    it("Should correctly return the right custom button props if rightCustomButton is not defined", () => {
      //given
      const mockedProps = {
        rightCustomButton: undefined,
      };
      //when
      const page = component({}, mockedProps);
      //then
      expect(page.vm.rightCustomButtonProps).toEqual(null);
    });

    it("Should correctly return the right custom button props if rightCustomButton is defined", () => {
      //given
      const mockedProps = {
        rightCustomButton: { enabled: true, link: "http://localhost:3000" },
      };
      //when
      const page = component({}, mockedProps);
      //then
      expect(page.vm.rightCustomButtonProps).toEqual(
        mockedProps.rightCustomButton
      );
    });
  });
});
